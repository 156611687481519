<template>
  <div class="AgreementDetail">
    <div class="row justify-center">
      <div class="col-auto">
        <q-btn-group
          :spread="isMobile"
          :class="{ 'fixed-bottom': isMobile, 'q-mt-md': !isMobile }"
          style="z-index:999;"
        >
          <q-btn icon="exit_to_app" class="buttonsBar" @click="exit" />
          <q-btn
            icon="account_tree"
            class="buttonsBar"
            @click="openHierarchy"
          />
        </q-btn-group>
      </div>
    </div>

    <q-scroll-area style="height: 80vh;">
      <div>
        <productsCard :data="agreement" />
      </div>
    </q-scroll-area>

    <div class="row justify-evenly q-mb-xl">
      <div class="col-auto">
        <q-btn color="green" icon="check" label="Aprovar" @click="approve" />
      </div>
      <div class="col-auto">
        <q-btn color="red" icon="block" label="Rejeitar" @click="reject" />
      </div>
    </div>

    <ApprovalHierarchy ref="ApprovalHierarchy" />

    <div class="RejectionReason">
      <q-dialog v-model="modalReason" persistent square full-width>
        <q-card class="bg-white">
          <q-card-section>
            <div class="text-h6">Rejeitar Acordo Comercial</div>
            <div class="text-subtitle2">
              Favor informar o motivo da rejeição:
            </div>
          </q-card-section>
          <q-card-section>
            <q-input
              v-model="descriptionRejection"
              type="textarea"
              label="Descrição do Motivo"
              outlined
            />
          </q-card-section>
          <q-card-section>
            <div class="row justify-evenly q-mb-xl">
              <div class="col-auto">
                <q-btn
                  color="grey-8"
                  label="Cancelar"
                  @click="modalReason = false"
                />
              </div>
              <div class="col-auto">
                <q-btn color="red" label="Rejeitar" @click="confirmReject" />
              </div>
            </div>
          </q-card-section>
        </q-card>
      </q-dialog>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import productsCard from "./productsCard";
import ApprovalHierarchy from "./ApprovalHierarchy";
import formatString from "@/helpers/formatString";
import removeCharSpecial from "../../helpers/removerCharSpecial";

export default {
  components: {
    productsCard,
    ApprovalHierarchy
  },
  data() {
    return {
      agreement: {},
      viewTable: false,
      modalReason: false,
      descriptionRejection: null,
      agreementSelected: null,
      dataForRejected: {}
    };
  },
  computed: {
    agreementId() {
      return this.$route.params.agreementId;
    },
    isMobile() {
      return this.$q.screen.xs;
    }
  },
  methods: {
    ...mapActions("tradeAgreement", [
      "getAgreementById",
      "approvesRejectsTradeAgreement"
    ]),
    ...mapMutations("shared", ["setNotification"]),
    format(value, type, decimals) {
      return formatString(value, type, decimals);
    },
    exit() {
      this.$router.back();
    },
    openHierarchy() {
      this.$refs.ApprovalHierarchy.open(this.agreement.approvalHierarchy);
    },
    approve() {
      this.sendResponse(this.agreement, "approved", "APROVAR", "text-green");
    },
    reject() {
      this.agreementSelected = this.agreement;
      this.sendResponse(this.agreement, "rejected", "REJEITAR", "text-red");
    },
    sendResponse(request, response, text, classMessage) {
      this.$q
        .dialog({
          title: `Confirmação`,
          message: `Deseja realmente <span class="${classMessage}">${text}</span> o Acordo ${request.agreementNumber}?`,
          html: true,
          ok: {
            push: true,
            color: "green",
            label: "Sim"
          },
          cancel: {
            push: true,
            color: "red",
            label: "Não"
          },
          persistent: true
        })
        .onOk(async () => {
          if (response === "rejected") {
            this.dataForRejected = {
              request: request,
              response: "rejected"
            };
            this.modalReason = true;
            return;
          }
          try {
            const reasonReject = "approved";
            await this.approvesRejectsTradeAgreement({
              request,
              response,
              reasonReject
            });
            this.setNotification({
              message: "Resposta enviada com sucesso.",
              color: "green",
              position: "top"
            });
            this.$router.push({ name: "agreement" });
          } catch (error) {
            this.setNotification({
              message: error.message,
              color: "red",
              position: "top"
            });
          }
        });
    },
    async confirmReject() {
      if (String(this.descriptionRejection).trim().length < 8) {
        this.$q.dialog({
          title: "Refresa Aprova",
          message: "Favor detalhar o motivo da Rejeição deste acordo!"
        });

        return;
      }

      try {
        const { request, response } = this.dataForRejected;
        const reasonReject = removeCharSpecial(this.descriptionRejection);
        await this.approvesRejectsTradeAgreement({
          request,
          response,
          reasonReject
        });
        this.setNotification({
          message: "Resposta enviada com sucesso.",
          color: "green",
          position: "top"
        });
        this.modalReason = false;
        this.$router.push({ name: "agreement" });
      } catch (error) {
        this.setNotification({
          message: error.message,
          color: "red",
          position: "top"
        });
      }
    }
  },
  async created() {
    try {
      this.agreement = await this.getAgreementById(this.agreementId);
    } catch (error) {
      console.log(error.message);
      this.$router.back();
    }
  }
};
</script>

<style lang="scss">
.AgreementDetail {
  width: 100%;
  .buttonsBar {
    background: #11275b;
    color: #fff;
  }
}
</style>
